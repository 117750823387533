// app/javascript/packs/fcm_notifications.js

// Only initialize Firebase Messaging if service workers are supported and messaging is supported by the browser
if ('serviceWorker' in navigator && firebase.messaging.isSupported()) {
  // Register the service worker for Firebase Messaging
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });

  // Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyB8o4FQyt-wpd2y6XIo4JKY-s4HiAOPzJs",
    authDomain: "byd-vsi-admin.firebaseapp.com",
    projectId: "byd-vsi-admin",
    storageBucket: "byd-vsi-admin.firebasestorage.app",
    messagingSenderId: "581742799337",
    appId: "1:581742799337:web:680f8d3a5c1db0262e4378",
    measurementId: "G-3587W4DDQ2"
  };

  // Initialize Firebase if it hasn't been initialized already
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const messaging = firebase.messaging();

  // Request permission and retrieve the FCM token
  async function requestNotificationPermission() {
    console.log("Requesting notification permission...");

    try {
      const permission = await Notification.requestPermission();
      console.log("Notification permission result:", permission);

      if (permission === "granted") {
        const currentToken = await messaging.getToken({
          vapidKey: "BG3dINE41Vfn-jUSbgPXaChDi5g9i7Xv-UuoGSxiYS_56ZAS1bJgUkzgZHFxRRI_o__fMU0Qc5Qb64BbDc_U2hQ",
        });

        if (currentToken) {
          console.log("FCM Token:", currentToken);
          saveTokenToServer(currentToken);
        } else {
          console.log("No registration token available.");
        }
      } else {
        console.log("Notification permission denied.");
      }
    } catch (error) {
      console.error("Error requesting permission or retrieving token:", error);
    }
  }

  // Save the FCM token to the server
  function saveTokenToServer(token) {
    fetch("/notification_tokens", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ notification_token: { token: token, platform: "fcm" } }),
    })
      .then(response => response.json())
      .then(data => console.log("Token saved:", data))
      .catch(error => console.error("Error saving token:", error));
  }

  // Handle incoming foreground messages
  messaging.onMessage((payload) => {
    console.log("Foreground message received:", payload);

    if (Notification.permission === "granted") {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon || '/default-icon.png', // Replace with your default icon if needed
        data: {
          url: payload.data.url // URL to redirect on notification click
        },
      };

      // Display the notification using the browser Notification API
      const notification = new Notification(notificationTitle, notificationOptions);

      // Handle click events on the notification
      notification.onclick = (event) => {
        event.preventDefault(); // Prevent default behavior
        console.log("Notification clicked. Redirecting to:", notificationOptions.data.url);
        notification.close();
        if (notificationOptions.data.url) {
          window.open(notificationOptions.data.url, '_blank'); // Open in a new tab
        }
      };
    }
  });

  // Expose the requestNotificationPermission function so it can be triggered elsewhere if needed
  window.requestNotificationPermission = requestNotificationPermission;

} else {
  console.log("Firebase Messaging is not supported on this browser.");
}
