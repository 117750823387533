document.addEventListener("turbolinks:load", function() {
    const tableElement = document.querySelector('#unpaid-bank-balance-table');
  
    if (tableElement) {
      if ($.fn.DataTable.isDataTable(tableElement)) {
        $(tableElement).DataTable().clear().destroy();
      }
  
      $(tableElement).DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: $(tableElement).data('source'),
          data: function(d) {
            d.proceeds_paid_false_or_dealer_incentives_false = true;
          }
        },
        pagingType: "full_numbers",
        columns: [
          { data: "customer_name" },
          { data: "vehicle_cs_no" },
          { data: "financed" },
          { data: "bank" },
          { data: "proceeds_paid" },
          { data: "orcr" },    // New ORCR column
          { data: "aging" }    // Aging column
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        responsive: true,
        rowCallback: function(row, data) {
          // Update indices if necessary
          // Column indices (0-based):
          // 0: customer_name
          // 1: vehicle_cs_no
          // 2: financed
          // 3: date_of_released
          // 4: bank
          // 5: proceeds_paid
          // 6: email_date
          // 7: status
          // 8: orcr
          // 9: aging
  
          // Update proceeds_paid cell styling
          var proceedsPaidCell = $('td:eq(4)', row);
          if (data.proceeds_paid === "false") {
            proceedsPaidCell.css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            proceedsPaidCell.css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
            proceedsPaidCell.html('Pending');
          } else if (data.proceeds_paid === "true") {
            proceedsPaidCell.css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            proceedsPaidCell.css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
            proceedsPaidCell.html('Paid');
          }
  
          // Optionally, style the ORCR column based on its value
          var orcrCell = $('td:eq(5)', row);
          if (data.orcr === "True") {
            orcrCell.css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            orcrCell.css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
          } else {
            orcrCell.css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            orcrCell.css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
          }
        },
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' },
          'copy',
          'pageLength'
        ]
      });
    }

    // Daily Walk-in Reports DataTable
    const walkInTableElement = document.querySelector('#daily-walk-in-reports-table');
    if (walkInTableElement) {
      console.log("Initializing Daily Walk-in Reports DataTable");

      // Clean up existing DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(walkInTableElement)) {
        console.log("Clearing existing DataTable");
        $(walkInTableElement).DataTable().clear().destroy();
      }

      // Initialize DataTable with server-side processing
      $(walkInTableElement).DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: walkInTableElement.dataset.source,
          data: function(d) {
            // Get date parameters from URL or input fields
            d.start_date = document.getElementById('start_date').value;
            d.end_date = document.getElementById('end_date').value;
            console.log("DataTable AJAX request with date range:", d.start_date, "to", d.end_date);
            return d;
          }
        },
        pagingType: "full_numbers",
        columns: [
          { data: 'full_name' },
          { data: 'customer_location' },
          { data: 'contact_number' },
          { data: 'unit_inquired' },
          { data: 'test_drive' },
          { data: 'reserved' },
          { data: 'sales_consultant' },
          { 
            data: 'status',
            render: function(data) {
              const colors = {
                'Hot': 'bg-red-100 text-red-800',
                'Warm': 'bg-yellow-100 text-yellow-800',
                'Cold': 'bg-blue-100 text-blue-800'
              };
              return `<span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${colors[data]}">${data}</span>`;
            }
          },
          { data: 'source' },
          { 
            data: 'date_visited',
            render: function(data) {
              return moment(data).format('YYYY-MM-DD');
            }
          }
        ],
        columnDefs: [
          { searchable: true, targets: [0, 1, 3, 6] },  // Customer Name, Location, Unit Inquired, Sales Consultant
          { searchable: false, targets: '_all' },  // All other columns
          { targets: "_all", className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500' }
        ],
        order: [[9, 'desc']],
        pageLength: 25,
        responsive: true,
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { 
            extend: 'colvis', 
            className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' 
          },
          {
            extend: 'excel',
            text: 'Download Excel',
            className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            exportOptions: {
              columns: ':visible'
            }
          },
          {
            extend: 'csv',
            text: 'Download CSV',
            className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            exportOptions: {
              columns: ':visible'
            }
          },
          'copy',
          'pageLength'
        ],
        drawCallback: function() {
          // After each draw, check if we need to update the date range display
          const startDate = document.getElementById('start_date');
          const endDate = document.getElementById('end_date');
          if (!startDate.value) {
            startDate.value = moment().startOf('month').format('YYYY-MM-DD');
          }
          if (!endDate.value) {
            endDate.value = moment().endOf('month').format('YYYY-MM-DD');
          }
        }
      });
    }

    // Allocations table initialization
    const allocationsTableElement = document.querySelector('#allocations-table');
    
    if (allocationsTableElement) {
      if ($.fn.DataTable.isDataTable(allocationsTableElement)) {
        $(allocationsTableElement).DataTable().clear().destroy();
      }
  
      $(allocationsTableElement).DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: allocationsTableElement.dataset.source
        },
        pagingType: "full_numbers",
        columns: [
          { data: 'customer' },
          { data: 'unit_reserved' },
          { data: 'vehicle_cs_no' },
          { data: 'allocation_date' },
          { data: 'duration' },
          { 
            data: 'status',
            render: function(data) {
              if (data === 'Sold') {
                return '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Sold</span>';
              } else {
                return '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Allocated</span>';
              }
            }
          },
          { data: 'actions', orderable: false, searchable: false }
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        responsive: true,
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' },
          'copy',
          'pageLength'
        ]
      });
    }
  });
  
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#unpaid-bank-balance-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }

    const allocationsTableElement = document.querySelector('#allocations-table');
    if (allocationsTableElement && $.fn.DataTable.isDataTable(allocationsTableElement)) {
      $(allocationsTableElement).DataTable().clear().destroy();
    }

    // Cleanup for daily walk-in reports table
    const walkInTableElement = document.querySelector('#daily-walk-in-reports-table');
    if (walkInTableElement && $.fn.DataTable.isDataTable(walkInTableElement)) {
      $(walkInTableElement).DataTable().clear().destroy();
    }
  });
  
  document.addEventListener("turbolinks:load", function() {
    $('#services-table').DataTable({
        processing: true,
        serverSide: true,
        ajax: $('#services-table').data('source'),
        columns: [
          { data: 'customer_name' },
          { data: 'vehicle_model' },
          { data: 'dealership' },
          { data: 'vehicle_id_no' },
          { data: 'plate_number' }
        ],
        columnDefs: [
            {
              targets: "_all",
              className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
            }
          ]
      });
  });


  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#services-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });

  document.addEventListener("turbolinks:load", function() {
    $('#reservations-table').DataTable({
      processing: true,
      serverSide: true,
      select: true,
      ajax: $('#reservations-table').data('source'),
      columns: [
        { data: 'customer' },
        { data: 'unit_reserved' },
        { data: 'unit_color' },
        { data: 'agent' },
        { data: 'reservation_date', type: 'date' },
        { data: 'branch' },
        { data: 'actions', orderable: false, searchable: false }
      ],
      columnDefs: [
        {
          targets: "_all",
          className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
        }
      ]
    });
  });

  document.addEventListener("turbolinks:load", function() {
    // Register the correct date format
    $.fn.dataTable.moment("MMMM D, YYYY");  // Example: "November 15, 2024"
  
    $('#reservations-table').DataTable({
      processing: true,
      serverSide: true,
      select: true,
      ajax: $('#reservations-table').data('source'),
      columns: [
        { data: 'customer' },
        { data: 'unit_reserved' },
        { data: 'unit_color' },
        { data: 'agent' },
        { data: 'reservation_date', type: 'date' }, // Ensure type is 'date'
        { data: 'branch' },
        { data: 'actions', orderable: false, searchable: false }
      ],
      columnDefs: [
        {
          targets: "_all",
          className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
        }
      ]
    });
  });
  
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#reservations-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });


  document.addEventListener("turbolinks:load", function() {
    $('#inventory-parts').DataTable({
        processing: true,
        serverSide: true,
        ajax: $('#inventory-parts').data('source'),
        columns: [
          { data: 'parts_id' },
          { data: 'part_name' },
          { data: 'quantity' },
          { data: 'dnp' },
          { data: 'srp' },
          { data: 'site' }
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'p-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ]
    });
  });
  
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#inventory-parts');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });
  
  document.addEventListener("turbolinks:load", function() {
    $('#service-jobs-table').DataTable({
      processing: true,
      serverSide: true,
      ajax: $('#service-jobs-table').data('source'),
      columns: [
        { data: 'customer_name' },
        { data: 'plate_number' },
        { data: 'vehicle_model' },
        { data: 'site' }
      ],
      columnDefs: [
        {
          targets: "_all",
          className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
        }
      ]
    });
  });
  
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#service-jobs-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });
  
  document.addEventListener("turbolinks:load", function() {
    const tableElement = document.querySelector('#for-approval-table');
  
    if (tableElement) {
      if ($.fn.DataTable.isDataTable(tableElement)) {
        $(tableElement).DataTable().clear().destroy();
      }
  
      $(tableElement).DataTable({
        processing: true,
        serverSide: true,
        ajax: {
          url: $(tableElement).data('source'),
          data: function(d) {
            d.ok_to_invoice_false_or_ok_to_release_false = true;
          }
        },
        pagingType: "full_numbers",
        columns: [
          { data: "customer_name" },
          { data: "vehicle_cs_no" },
          { data: "ok_to_invoice" },
          { data: "ok_to_release" },
          { data: "date_of_release" }
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        responsive: true,
        "rowCallback": function(row, data) {
          if (data.ok_to_invoice === "No") {
            $('td:eq(2)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            $('td:eq(2)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
          } else if (data.ok_to_invoice === "Yes") {
            $('td:eq(2)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            $('td:eq(2)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
          }
  
          if (data.ok_to_release === "No") {
            $('td:eq(3)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
            $('td:eq(3)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
          } else if (data.ok_to_release === "Yes") {
            $('td:eq(3)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
            $('td:eq(3)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
          }
        },
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' },
          'copy',
          'pageLength'
        ]
      });
    }
  });
  
  // Destroy DataTable before TurboLinks caches the page
  document.addEventListener("turbolinks:before-cache", function() {
    const tableElement = document.querySelector('#for-approval-table');
    if (tableElement && $.fn.DataTable.isDataTable(tableElement)) {
      $(tableElement).DataTable().clear().destroy();
    }
  });
  
  document.addEventListener("DOMContentLoaded", function() {
    if (!firebase.messaging.isSupported()) {
      console.warn("FCM is not supported on this browser.");
      return;
    }
  
    const messaging = firebase.messaging();
  
    function requestPermission() {
      return Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          return messaging.getToken({ vapidKey: "BG3dINE41Vfn-jUSbgPXaChDi5g9i7Xv-UuoGSxiYS_56ZAS1bJgUkzgZHFxRRI_o__fMU0Qc5Qb64BbDc_U2hQ" });
        } else {
          console.log("Notification permission denied.");
        }
      });
    }
  
    function sendTokenToServer(token) {
      fetch("/notification_tokens", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
        },
        body: JSON.stringify({ notification_token: { token: token, platform: "fcm" } })
      }).then(response => {
        if (!response.ok) {
          console.error("Failed to register token with server");
        }
      });
    }
  
    // Request notification permission and get token
    requestPermission().then(token => {
      if (token) {
        sendTokenToServer(token);
      }
    });
  
    // Update token if refreshed
    messaging.onTokenRefresh(() => {
      messaging.getToken().then(refreshedToken => {
        console.log("Token refreshed.");
        sendTokenToServer(refreshedToken);
      });
    });
  
    // Handle incoming messages
    messaging.onMessage(payload => {
      console.log("Message received:", payload);
      // You could show a notification directly here if needed
    });
  });
  
  document.addEventListener("turbolinks:load", function() {
    const circularMemosTable = document.querySelector('#circular-memos-table');
    
    if (circularMemosTable) {
      if ($.fn.DataTable.isDataTable(circularMemosTable)) {
        $(circularMemosTable).DataTable().clear().destroy();
      }
  
      $(circularMemosTable).DataTable({
        processing: true,
        serverSide: true,
        ajax: circularMemosTable.dataset.source,
        columns: [
          { data: 'title' },
          { data: 'memo_type' },
          { data: 'created_at' },
          { data: 'pdf' }
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        responsive: true,
        dom: 'Bfrtip',
        buttons: [
          { 
            extend: 'colvis', 
            className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50' 
          },
          'pageLength'
        ],
        order: [[0, 'desc']]
      });
    }
  });
  
  document.addEventListener("turbolinks:before-cache", function() {
    const circularMemosTable = document.querySelector('#circular-memos-table');
    if (circularMemosTable && $.fn.DataTable.isDataTable(circularMemosTable)) {
      $(circularMemosTable).DataTable().clear().destroy();
    }
  });
  
  document.addEventListener("turbolinks:load", function() {
    const allocationsTable = document.querySelector('#allocations-table');
    
    if (allocationsTable) {
      if ($.fn.DataTable.isDataTable(allocationsTable)) {
        $(allocationsTable).DataTable().clear().destroy();
      }
  
      // Register the correct date format
      $.fn.dataTable.moment("MMMM D, YYYY");
  
      $(allocationsTable).DataTable({
        processing: true,
        serverSide: true,
        select: true,
        ajax: allocationsTable.dataset.source,
        columns: [
          { data: 'customer' },
          { data: 'unit_reserved' },
          { data: 'vehicle_cs_no' },
          { data: 'allocation_date', type: 'date' },
          { data: 'duration' },
          { 
            data: 'status',
            render: function(data) {
              if (data === 'Sold') {
                return '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Sold</span>';
              } else {
                return '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Allocated</span>';
              }
            }
          },
          { data: 'actions', orderable: false, searchable: false }
        ],
        columnDefs: [
          {
            targets: "_all",
            className: 'px-6 py-4 whitespace-nowrap border-b text-sm leading-5 text-gray-500'
          }
        ],
        createdRow: function(row, data, dataIndex) {
          if (data.DT_RowClass) {
            $(row).addClass(data.DT_RowClass);
          }
        },
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
          { 
            extend: 'colvis', 
            className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            text: 'Show/Hide Columns'
          },
          {
            extend: 'pageLength',
            className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
          },
          {
            extend: 'excel',
            text: 'Download Excel',
            className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            exportOptions: {
              columns: ':visible'
            }
          },
          {
            extend: 'csv',
            text: 'Download CSV',
            className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            exportOptions: {
              columns: ':visible'
            }
          }
        ],
        lengthMenu: [
          [10, 25, 50, -1],
          ['10 rows', '25 rows', '50 rows', 'Show all']
        ],
        pageLength: 25
      });
    }
  });
  
  document.addEventListener("turbolinks:before-cache", function() {
    const allocationsTable = document.querySelector('#allocations-table');
    if (allocationsTable && $.fn.DataTable.isDataTable(allocationsTable)) {
      $(allocationsTable).DataTable().clear().destroy();
    }
  });
  